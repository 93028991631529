import { useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, View, Linking } from 'react-native';

import MainContainer from '../../components/Common/MainContainer';
import config from '../../config';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { useStore } from '../../stores';

const SignIn: React.FC = () => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const store = useStore();
  const { authStore } = store;
  const [isLoading, setIsLoading] = useState(false);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const getAccessToken = async () => {
    let url = `https://login.microsoftonline.com/${config.microsoftAuthTenantId}/oauth2/v2.0/authorize`;
    url += '?prompt=select_account';
    url += `&client_id=${config.microsoftAuthClientId}`;
    url += '&response_type=code';
    url += `&redirect_uri=${encodeURIComponent(
      config.microsoftAuthRedirectUrl,
    )}`;
    url += '&response_mode=query';
    url += '&scope=offline_access profile openid sites.read.all';

    // TODO: Replace scope above with this on migration to Dataverse
    // url += `&scope=offline_access profile openid ${config.dataverseScopeUrl}`;

    const canLoginWithMicrosoft = await Linking.canOpenURL(url);
    if (canLoginWithMicrosoft) {
      // @ts-ignore
      return Linking.openURL(url, '_self');
      // replace '_self' with '_blank' to open link in new tab
    }
  };

  const fetchToken = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    if (authStore.accessToken) {
      await authStore.logOut();
    }
    await getAccessToken();

    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }

    setIsLoading(false);
  };

  useEffect(() => {
    intervalIdRef.current = setInterval(() => {
      fetchToken();
    }, 2000);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  return (
    <MainContainer style={styles.container}>
      <View style={styles.loading}>
        <ActivityIndicator size="large" color={theme['bp-green']} />
      </View>
    </MainContainer>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    loading: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
});

export default observer(SignIn);
